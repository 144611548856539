import React from "react";
import QuoteRequestModalHomeLibrary from "./quote-request-modal-home-library";

const services = [
    {
        title: 'Complete Structural Demolition',
        cards:[
            {
                title: 'House Demolition',
                code: 'house-demolition',
                ondeffCode: 'demolitionQuoteRequest',
                image:
                {
                    alt: 'House Demolition',
                    path: '/images/keygroups/Scroll-Image---House-Demolition.webp'
                }
            },
            {
                title: 'Commercial Building Demolition',
                code: 'commercial-demolition',
                ondeffCode: 'demolitionQuoteRequest',
                image:
                {
                    alt: 'Commercial Building Demolition',
                    path: '/images/keygroups/Scroll-Image---Commercial-Demolition.webp'
                }
            },
            {
                title: 'Mobile Home Demolition',
                code: 'mobile-home-demolition',
                ondeffCode: 'demolitionQuoteRequest',
                image:
                {
                    alt: 'Mobile Home Demolition',
                    path: '/images/keygroups/Scroll-Image---Mobile-Home-Demolition.webp'
                }
            },
            {
                title: 'Garage Demolition',
                code: 'house-demolition',
                ondeffCode: 'demolitionQuoteRequest',
                image:
                {
                    alt: 'Garage Demolition',
                    path: '/images/keygroups/Scroll-Image---Garage-Demolition.webp'
                }
            },
            {
                title: 'Barn Demolition',
                code: 'barn-demolition',
                ondeffCode: 'demolitionQuoteRequest',
                image:
                {
                    alt: 'Barn Demolition',
                    path: '/images/keygroups/Scroll-Image---Barn-Demolition.webp'
                }
            }                    
        ]
    },
    {
        title: 'Swimming Pool & Concrete Removal',
        cards:[
            {
                title: 'Inground Pool Removal',
                code: 'pool-removal',
                ondeffCode: 'demolitionQuoteRequest',
                image:
                {
                    alt: 'Inground Pool Removal',
                    path: '/images/keygroups/Scroll-Image---Inground-Pool-Removal.webp'
                }
            },
            {
                title: 'Concrete Driveway Removal',
                code: 'concrete-removal',
                ondeffCode: 'demolitionQuoteRequest',
                image:
                {
                    alt: 'Concrete Driveway Removal',
                    path: '/images/keygroups/Scroll-Image---Concrete-Driveway-Removal.webp'
                }
            },
            {
                title: 'Asphalt Parking Lot Removal',
                code: 'concrete-removal',
                ondeffCode: 'demolitionQuoteRequest',
                image:
                {
                    alt: 'Asphalt Parking Lot Removal',
                    path: '/images/keygroups/Scroll-Image---Asphalt-Parking-Lot-Removal.webp'
                }
            },
            {
                title: 'Above Ground Pool Removal',
                code: 'pool-removal',
                ondeffCode: 'demolitionQuoteRequest',
                image:
                {
                    alt: 'Above Ground Pool Removal',
                    path: '/images/keygroups/Scroll-Image---Above-Ground-Pool-Removal.webp'
                }
            },
            {
                title: 'Concrete Sidewalk Removal',
                code: 'concrete-removal',
                ondeffCode: 'demolitionQuoteRequest',
                image:
                {
                    alt: 'Concrete Sidewalk Removal',
                    path: '/images/keygroups/Scroll-Image---Concrete-Sidewalk-Removal.webp'
                }
            }                    
        ]
    },
    {
        title: 'Interior & Selective Demolition',
        cards:[
            {
                title: 'Commercial Interior Demolition',
                code: 'interior-demolition',
                ondeffCode: 'demolitionQuoteRequest',
                image:
                {
                    alt: 'Commercial Interior Demolition',
                    path: '/images/keygroups/Scroll-Image---Commercial-Interior-Demolition.webp'
                }
            },
            {
                title: 'Residential Interior Demolition',
                code: 'interior-demolition',
                ondeffCode: 'demolitionQuoteRequest',
                image:
                {
                    alt: 'Residential Interior Demolition',
                    path: '/images/keygroups/Scroll-Image---Residential-Interior-Demolition.webp'
                }
            },
            {
                title: 'Chimney Removal',
                code: 'chimney-removal',
                ondeffCode: 'demolitionQuoteRequest',
                image:
                {
                    alt: 'Chimney Removal',
                    path: '/images/keygroups/Scroll-Image---Chimney-Removal.webp'
                }
            },
            {
                title: 'Oil Tank Removal',
                code: 'oil-tank-removal',
                ondeffCode: 'demolitionQuoteRequest',
                image:
                {
                    alt: 'Oil Tank Removal',
                    path: '/images/keygroups/Scroll-Image---Oil-Tank-Removal.webp'
                }
            },
            {
                title: 'Deck Removal',
                code: 'residential-demolition',
                ondeffCode: 'demolitionQuoteRequest',
                image:
                {
                    alt: 'Deck Removal',
                    path: '/images/keygroups/Scroll-Image---Deck-Removal.webp'
                }
            }                    
        ]
    }                
]

export default class KeygroupsImagesHome extends React.Component  
{   
    render()
    {
        return (
            <div className="container-fluid keygroups-images">      
                <div className="container">          
                    <div className="row">
                    {
                            services.map(service =>
                            {                                                 
                                return(
                                    <div className="col-12">
                                        <h3>{service.title}</h3>
                                            <div className="cards">
                                            {
                                                service.cards.map((card) =>
                                                (
                                                    <QuoteRequestModalHomeLibrary
                                                        service={card}
                                                        startVisible={false} 
                                                    />
                                                ))
                                            } 
                                        </div>                                   
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }
};