import React from "react"
import HeaderMenuDemolition from "./header-menu-demolition"
import ZipFinderHomeSmall from "./zip-finder-home-small";

export default function HeaderHomeDemolition(props) 
{
	const env = props.env;
	const code = props.code;
	
	return (
		<nav id="sitenav" className="col-12 navbar navbar-expand-md navbar-light">			
			
			<div className="elements">
			
				<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={() => {document.getElementById('navbarSupportedContent').classList.toggle('show')}}>   
					<span className="sr-only"></span>
					<span className="icon-bar"></span>
					<span className="icon-bar"></span>
					<span className="icon-bar"></span>    
				</button>

				<a className="nav-bar brand" href="/" title="Home">
					<img src="/images/logo-demolition.webp" alt="logo"></img>
				</a>

			</div>
            
			<HeaderMenuDemolition />

			<ZipFinderHomeSmall
				id='header-zip-finder' 
				env={env} 
				code={code} 
				button = "Get Started"
			/>

		</nav>		
	);
}