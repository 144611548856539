import React, { useEffect } from "react";
import PopularProjectsNearYou from "../components/popular-projects-near-you";
import ProjectTypesList from "../components/project-types-list";
import ZipFinderHome from "../components/zip-finder-home";
import KeygroupsImagesHome from "../components/keygroups-images-home";
import HowNearMeDemolition from "../components/how-nearme-demolition";
import CitiesListHome from "../components/cities-list-home";
import AreYouAProfessionalDemolition from "../components/are-you-a-professional-demolition";
import StatesListHome from "../components/state-list-home";
import LayoutHomeDemolition from "../components/layout-home-demolition";

const DemolitionIndex = ({ data, pageContext }) => {
    const states = pageContext.states.length > 0 ? pageContext.states : [];
    const codes = pageContext.codes.length > 0 ? pageContext.codes : [];
    const env = pageContext.env;
    const project_types = pageContext.project_types
    const popular_projects = pageContext.popular_projects
    const cities = pageContext.cities.length > 0 ? pageContext.cities : [];
    const HUB = pageContext.HUB;

    if (HUB.length > 0) cities.push(HUB[0])

    const seo_desc = pageContext.seo_desc;
    const seo =
    {
        url: '/',
        env: env,
        code: 'demolition',
        page: 'home',
        seo_desc: seo_desc
    }

    useEffect(() => {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        if(params.about) document.getElementById("state-list-section").scrollIntoView();
    },[])

    return (

        <LayoutHomeDemolition seo={seo} env={env} code='demolition'>

            <ZipFinderHome
                id='home-zip-code'
                code='demolition'
                title="Demolish. Remove. Renew."
                text="Find the right contractor to demolish and remove anything"
                button="Get Started"
                bottomtext="America's #1 Source for Local Demolition Experts"
                env={env}
                class='small'
            />

            <KeygroupsImagesHome />

            <PopularProjectsNearYou popular_projects={popular_projects} env={env}/>

            <HowNearMeDemolition
                page='home'
            />

            <CitiesListHome
                cities={cities}
                code='dumpster'
                codes={codes}
                env={env} 
            />

            <AreYouAProfessionalDemolition />


            <StatesListHome
                states={states}
                code='main'
                title='Find Demolition Contractors in All 50 States'
                codes={codes}
                env={env}
            />

            <ProjectTypesList
                title='Popular Categories'
                project_types={project_types}
                env={env}
            />

        </LayoutHomeDemolition>
    )
};

export default DemolitionIndex;