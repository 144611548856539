import React from "react";

export default class AreYouAProfessionalDemolition extends React.Component 
{
    reviewForm = () =>
    {
        if (typeof window !== "undefined") window.open('https://hometown.wufoo.com/forms/get-started/',  null, 'height=600, width=680, toolbar=0, location=0, status=1, scrollbars=1, resizable=1'); 
        
        return false
    }
    
    render() 
    {    
        return (
            <section className="banner-contractor demolition">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8 pl-m-0">
                            <div className="row align-items-center">
                                <div className="col-12 col-md-5 order-1 order-md-0 text-center p-m-0">
                                    <img className="banner-img" src='/images/contractor-cob.webp' alt="Pro dumpster service"/>
                                </div>
                                <div className="col-12 col-md-7 text-center order-0 order-md-1">
                                    <h2>Pros Get Started</h2>
                                    <h4 dangerouslySetInnerHTML={{__html: "More customers visit Hometown Demolition</br> to hire a demo pro than any other source."}}></h4>
                                    <a className="btn btn-success btn-sm btn-lite" href='#' onClick={this.reviewForm}>Pros Learn More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
};